import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import {
  updateUserProfile,
  getUser,
  deleteUserData,
} from "../services/firestore";
import { deleteUserAccount } from "../services/firebase";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../components/ui/card";
import { generateRandomAvatar } from "../utils/avatarGenerator";
import { useNavigate } from "react-router-dom";
import { useToast } from "../components/ui/toast";

export default function ProfilePage() {
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();
  const [nickname, setNickname] = useState(user?.nickname || "");
  const [savingNickname, setSavingNickname] = useState(false);
  const [updatingActivity, setUpdatingActivity] = useState(false);
  const [updatingAvatar, setUpdatingAvatar] = useState(false);
  const [error, setError] = useState("");
  const [shareActivity, setShareActivity] = useState(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadUserProfile();
  }, [user?.uid]);

  const loadUserProfile = async () => {
    try {
      const userData = await getUser(user.uid);
      setShareActivity(!!userData.shareGitHubActivity);
      setNickname(userData.nickname || "");
    } catch (err) {
      console.error("프로필 로드 실패:", err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nickname.trim()) {
      setError("닉네임을 입력해주세요.");
      return;
    }

    try {
      setSavingNickname(true);
      setError("");

      await updateUserProfile(user.uid, {
        nickname: nickname.trim(),
      });

      addToast("프로필이 업데이트되었습니다.", "info");
    } catch (err) {
      console.error("프로필 업데이트 실패:", err);
      setError("프로필 업데이트에 실패했습니다.");
    } finally {
      setSavingNickname(false);
    }
  };

  const handleActivitySharingToggle = async () => {
    try {
      setUpdatingActivity(true);
      
      // Firestore 업데이트
      await updateUserProfile(user.uid, {
        shareGitHubActivity: !shareActivity
      });
  
      // 로컬 상태 업데이트
      setShareActivity(!shareActivity);
  
      // Context 업데이트 추가
      updateUser({
        shareGitHubActivity: !shareActivity
      });
  
      addToast(
        !shareActivity
          ? "GitHub 활동이 다른 사용자들에게 공개됩니다"
          : "GitHub 활동이 비공개로 설정되었습니다",
        "success"
      );
    } catch (err) {
      console.error("활동 공개 설정 변경 실패:", err);
      addToast("설정 변경에 실패했습니다", "error");
    } finally {
      setUpdatingActivity(false);
    }
  };

  const handleRegenerateAvatar = async () => {
    try {
      setUpdatingAvatar(true);
      // 현재 시간을 시드에 추가하여 새로운 아바타 생성
      const newAvatarUrl = generateRandomAvatar(user.uid + Date.now());

      await updateUserProfile(user.uid, {
        photoURL: newAvatarUrl,
      });

      addToast("프로필 이미지가 변경되었습니다", "success");
      window.location.reload(); // 새 아바타를 바로 반영하기 위해 페이지 새로고침
    } catch (err) {
      console.error("아바타 변경 실패:", err);
      addToast("아바타 변경에 실패했습니다", "error");
    } finally {
      setUpdatingAvatar(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (
      !window.confirm("정말로 탈퇴하시겠습니까? 이 작업은 되돌릴 수 없습니다.")
    ) {
      return;
    }

    try {
      setDeletingAccount(true);
      setError("");

      try {
        // 1. Firestore 사용자 데이터 삭제
        await deleteUserData(user.uid);

        // 2. Firebase Authentication 계정 삭제 (재인증 포함)
        await deleteUserAccount();

        // 3. 홈페이지로 리다이렉트
        navigate("/");
      } catch (err) {
        // 재인증 관련 에러인 경우
        if (err.code === "auth/requires-recent-login") {
          addToast("보안을 위해 GitHub 재로그인이 필요합니다. GitHub 로그인 창이 나타나면 다시 로그인해주세요.", "error");
          throw err;
        }
        throw err;
      }
    } catch (err) {
      console.error("계정 삭제 실패:", err);
      setError(
        err.code === "auth/requires-recent-login"
          ? "계정 삭제를 위해서는 재로그인이 필요합니다. 다시 시도해주세요."
          : "계정 삭제에 실패했습니다. 잠시 후 다시 시도해주세요."
      );
    } finally {
      setDeletingAccount(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* 프로필 설정 카드 */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>프로필 설정</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* 프로필 이미지 섹션 */}
            <div className="flex items-center space-x-4">
              <div className="relative">
                <img
                  src={user.photoURL}
                  alt={user.nickname || user.displayName}
                  className="w-16 h-16 rounded-full"
                />
                <button
                  type="button"
                  onClick={handleRegenerateAvatar}
                  disabled={updatingAvatar}
                  className="absolute -bottom-2 -right-2 p-1 bg-white rounded-full shadow-md hover:bg-gray-50 disabled:opacity-50"
                  title="새로운 아바타 생성"
                >
                  <svg
                    className="w-4 h-4 text-gray-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <p className="text-sm text-gray-500">프로필 아바타</p>
                <p className="text-sm font-medium">
                  {user.nickname || user.displayName}
                </p>
              </div>
            </div>

            {/* 닉네임 입력 */}
            <div className="space-y-2">
              <label
                htmlFor="nickname"
                className="block text-sm font-medium text-gray-700"
              >
                닉네임
              </label>
              <input
                type="text"
                id="nickname"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                className="w-full px-3 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="닉네임을 입력하세요"
              />
            </div>

            {error && <p className="text-sm text-red-600">{error}</p>}

            {/* 저장 버튼 */}
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={savingNickname}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400"
              >
                {savingNickname ? "저장 중..." : "저장"}
              </button>
            </div>
          </form>
        </CardContent>
      </Card>

      {/* GitHub 활동 공개 설정 카드 */}
      <Card>
        <CardHeader>
          <CardTitle>GitHub 활동 설정</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  커밋 그래프 공개 설정
                </h3>
                <p className="text-sm text-gray-500">
                  내 GitHub 활동을 다른 사용자들에게 공개할지 설정합니다.
                </p>
              </div>
              <div className="flex items-center">
                <button
                  type="button"
                  disabled={updatingActivity}
                  onClick={handleActivitySharingToggle}
                  className={`
                    relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full 
                    border-2 border-transparent transition-colors duration-200 ease-in-out 
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                    ${shareActivity ? "bg-blue-600" : "bg-gray-200"}
                    ${updatingActivity ? "opacity-50 cursor-not-allowed" : ""}
                  `}
                >
                  <span
                    className={`
                      pointer-events-none inline-block h-5 w-5 transform rounded-full 
                      bg-white shadow ring-0 transition duration-200 ease-in-out
                      ${shareActivity ? "translate-x-5" : "translate-x-0"}
                    `}
                  />
                </button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* 계정 삭제 카드 */}
      <Card className="mt-6 border-red-200">
        <CardHeader>
          <CardTitle className="text-red-600">계정 삭제</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                계정 영구 삭제
              </h3>
              <div className="bg-gray-50 p-4 rounded-lg mb-4">
                <h4 className="font-medium text-gray-900 mb-2">
                  계정 삭제 전 확인사항
                </h4>
                <ul className="text-sm text-gray-600 space-y-2">
                  <li className="flex items-start">
                    <span className="text-red-500 mr-2">•</span>
                    <span>
                      계정 삭제는 되돌릴 수 없으며, 서비스에 사용되었던 모든 데이터가 삭제됩니다.
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-red-500 mr-2">•</span>
                    <span>보안을 위해 GitHub 재인증이 필요합니다.</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-red-500 mr-2">•</span>
                    <span>
                      GitHub 로그인 창이 나타나면 현재 계정으로 다시
                      로그인해주세요.
                    </span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-red-500 mr-2">•</span>
                    <span>
                      GitHub 계정, 저장소 등 Github 계정 데이터에는 전혀 영향이 없습니다.
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {!showDeleteConfirm ? (
              <button
                type="button"
                onClick={() => setShowDeleteConfirm(true)}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                계정 삭제 시작
              </button>
            ) : (
              <div className="space-y-4">
                <div className="bg-red-50 p-4 rounded-lg">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-red-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        정말로 계정을 삭제하시겠습니까?
                      </h3>
                      <p className="mt-2 text-sm text-red-700">
                        GitHub 재인증 후 계정이 즉시 삭제됩니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    onClick={handleDeleteAccount}
                    disabled={deletingAccount}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-gray-400"
                  >
                    {deletingAccount ? "삭제 중..." : "예, 영구 삭제하겠습니다"}
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowDeleteConfirm(false)}
                    disabled={deletingAccount}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    취소
                  </button>
                </div>
                {error && <p className="text-sm text-red-600 mt-2">{error}</p>}
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
