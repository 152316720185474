import React, { useState, useRef, useEffect } from 'react';

const ContributionGraph = ({ contributions }) => {
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(true);
  
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollContainerRef.current) return;
      
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftShadow(scrollLeft > 0);
      setShowRightShadow(scrollLeft < scrollWidth - clientWidth - 10);
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const generateDates = () => {
    const dates = [];
    const start = new Date(new Date().getFullYear(), 0, 1);
    const end = new Date();
    
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      dates.push(new Date(d));
    }
    return dates;
  };

  const getContributionLevel = (count) => {
    if (count === 0) return 0;
    if (count <= 3) return 1;
    if (count <= 6) return 2;
    if (count <= 9) return 3;
    return 4;
  };

  const getWeeks = (dates) => {
    const weeks = [];
    let week = [];
    
    const firstDay = dates[0].getDay();
    for (let i = 0; i < firstDay; i++) {
      week.push(null);
    }

    dates.forEach((date) => {
      week.push(date);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    });

    if (week.length > 0) {
      while (week.length < 7) {
        week.push(null);
      }
      weeks.push(week);
    }

    return weeks;
  };

  const getMonthLabels = (dates) => {
    const months = [];
    let currentMonth = -1;

    dates.forEach((date, index) => {
      const monthNum = date.getMonth();
      if (monthNum !== currentMonth) {
        months.push({
          name: (monthNum + 1) + '월',
          index: index
        });
        currentMonth = monthNum;
      }
    });

    return months;
  };

  const handleCellMouseEnter = (e, date, count) => {
    if (!date) return;
    
    const rect = e.target.getBoundingClientRect();
    setTooltipPosition({
      x: rect.left + window.scrollX,
      y: rect.top + window.scrollY - 40
    });
    
    const formattedDate = new Intl.DateTimeFormat('ko-KR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
    
    setTooltipContent(`${count}개의 커밋, ${formattedDate}`);
    setTooltipVisible(true);
  };

  const handleCellMouseLeave = () => {
    setTooltipVisible(false);
  };

  const dates = generateDates();
  const weeks = getWeeks(dates);
  const monthLabels = getMonthLabels(dates);

  // 마지막 주의 마지막 날짜를 찾아 필요한 너비 계산
  const lastDate = dates[dates.length - 1];
  const totalWeeks = Math.ceil((lastDate.getTime() - dates[0].getTime()) / (7 * 24 * 60 * 60 * 1000)) + 1;
  const containerWidth = totalWeeks * 15 + 30; // 15px(셀 너비 + 간격) * 주 수 + 여백

  return (
    <div className="relative w-full">
      {showLeftShadow && (
        <div className="absolute left-0 top-0 bottom-0 w-32 pointer-events-none z-10"
          style={{
            background: 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))'
          }}
        />
      )}
      
      {showRightShadow && (
        <div className="absolute right-0 top-0 bottom-0 w-32 pointer-events-none z-10"
          style={{
            background: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))'
          }}
        />
      )}

      <div 
        ref={scrollContainerRef}
        className="w-full overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
      >
        <div style={{ width: `${containerWidth}px` }} className="pt-4 pb-2">
          <div className="flex mb-2 text-xs text-gray-500 h-4 relative ml-[22px]">
            {monthLabels.map((month, idx) => (
              <div
                key={idx}
                className="absolute"
                style={{
                  left: `${Math.floor(month.index / 7) * 15}px`,
                }}
              >
                {month.name}
              </div>
            ))}
          </div>

          <div className="flex">
            <div className="flex flex-col justify-between h-[105px] mr-2 text-xs text-gray-500 w-4">
              <span>일</span>
              <span>토</span>
            </div>

            <div className="flex gap-[3px]">
              {weeks.map((week, weekIndex) => (
                <div key={weekIndex} className="flex flex-col gap-[3px]">
                  {week.map((date, dayIndex) => {
                    if (!date) return <div key={dayIndex} className="w-[12px] h-[12px]" />;
                    
                    const dateStr = date.toISOString().split('T')[0];
                    const count = contributions?.[dateStr] || 0;
                    const level = getContributionLevel(count);
                    
                    return (
                      <div
                        key={dayIndex}
                        className={`
                          w-[12px] h-[12px] rounded-sm cursor-pointer 
                          transition-colors duration-100
                          ${level === 0 ? 'bg-gray-100 hover:bg-gray-200' :
                            level === 1 ? 'bg-green-200 hover:bg-green-300' :
                            level === 2 ? 'bg-green-300 hover:bg-green-400' :
                            level === 3 ? 'bg-green-400 hover:bg-green-500' :
                            'bg-green-500 hover:bg-green-600'}
                        `}
                        onMouseEnter={(e) => handleCellMouseEnter(e, date, count)}
                        onMouseLeave={handleCellMouseLeave}
                      />
                    );
                  })}
                </div>
              ))}
            </div>
          </div>

          {tooltipVisible && (
            <div
              className="absolute bg-gray-900 text-white px-3 py-2 rounded text-xs"
              style={{
                left: tooltipPosition.x + 'px',
                top: tooltipPosition.y + 'px',
                transform: 'translate(-50%, -100%)',
                pointerEvents: 'none',
                zIndex: 50
              }}
            >
              {tooltipContent}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContributionGraph;