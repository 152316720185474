// 스타일 그룹화 (비슷한 스타일끼리 묶음)
const styleGroups = {
  modern: [
    'micah',        // 현대적인 스타일
    'avataaars',    // 아바타 스타일
    'personas',     // 페르소나 스타일
    'miniavs'       // 미니 아바타
  ],
  artistic: [
    'adventurer',
    'adventurer-neutral',
    'croodles',
    'croodles-neutral',
    'lorelei',
    'lorelei-neutral'
  ],
  fun: [
    'big-ears',
    'big-ears-neutral',
    'bottts',       // 로봇 스타일
    'fun-emoji',    // 이모지 스타일
    'pixel-art',
    'pixel-art-neutral'
  ]
};

// 모든 스타일을 하나의 배열로 합치기
const allStyles = Object.values(styleGroups).flat();

// 시드값으로부터 일관된 랜덤값 생성
const getRandomFromSeed = (seed, max) => {
  const numericSeed = seed.split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return numericSeed % max;
};

export const generateRandomAvatar = (seed) => {
  // 시드값이 없으면 랜덤 문자열 생성
  const finalSeed = seed || Math.random().toString(36).substring(7);
  
  // 시드값을 기반으로 스타일 선택
  const styleIndex = getRandomFromSeed(finalSeed, allStyles.length);
  const style = allStyles[styleIndex];
  
  console.log('Selected avatar style:', style); // 디버깅용

  // DiceBear API URL 생성
  return `https://api.dicebear.com/7.x/${style}/svg?seed=${finalSeed}`;
};

// 특정 그룹에서 스타일 선택 (옵션)
export const generateAvatarFromGroup = (group, seed) => {
  const styles = styleGroups[group];
  if (!styles) {
    throw new Error('Invalid style group');
  }
  
  const finalSeed = seed || Math.random().toString(36).substring(7);
  const styleIndex = getRandomFromSeed(finalSeed, styles.length);
  const style = styles[styleIndex];
  
  return `https://api.dicebear.com/7.x/${style}/svg?seed=${finalSeed}`;
};

// 사용 가능한 스타일 정보 (참고용)
export const availableStyles = {
  all: allStyles,
  groups: styleGroups
};