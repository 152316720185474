import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import UserActivity from '../components/dashboard/UserActivity';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { getPublicUserActivities, fetchUserContributions } from '../services/github';

const DashboardPage = () => {
  const { user } = useAuth();
  const [activeUsers, setActiveUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    try {
      setLoading(true);
      setError(null);

      // 활동을 공개로 설정한 사용자들 조회
      const publicUsers = await getPublicUserActivities();
      
      // 각 사용자의 GitHub 데이터 조회
      const usersWithContributions = await Promise.all(
        publicUsers.map(async (publicUser) => {
          const githubData = await fetchUserContributions(publicUser.githubUsername);
          return {
            ...publicUser,
            githubData
          };
        })
      );

      // 최근 활동순으로 정렬
      const sortedUsers = usersWithContributions
        .filter(user => user.githubData) // 유효한 데이터만 필터링
        .sort((a, b) => b.githubData.totalContributions - a.githubData.totalContributions);

      setActiveUsers(sortedUsers);
    } catch (err) {
      console.error('Failed to load dashboard data:', err);
      setError('데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* 내 활동 섹션 */}
      <section className="mb-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">내 활동</h2>
        <UserActivity />
      </section>

      {/* 전체 활동 현황 섹션 */}
      <section className="mb-12">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">전체 활동 현황</h2>
          <button
            onClick={loadDashboardData}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            새로고침
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-48">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : error ? (
          <div className="text-center text-red-600 py-8">{error}</div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {activeUsers.map(userData => (
              <Card key={userData.id} className="overflow-hidden">
                <CardHeader className="pb-4">
                  <div className="flex items-center space-x-4">
                    <img
                      src={userData.photoURL}
                      alt={userData.nickname || userData.displayName}
                      className="h-10 w-10 rounded-full"
                    />
                    <div>
                      <CardTitle className="text-lg">
                        {userData.nickname || userData.displayName}
                      </CardTitle>
                      <a
                        href={`https://github.com/${userData.githubUsername}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-gray-500 hover:text-gray-700"
                      >
                        @{userData.githubUsername}
                      </a>
                    </div>
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    {/* 간단한 통계 */}
                    <div className="grid grid-cols-3 gap-4 border-t pt-4">
                      <div className="text-center">
                        <div className="text-2xl font-bold text-gray-900">
                          {userData.githubData?.totalContributions || 0}
                        </div>
                        <div className="text-xs text-gray-500">총 커밋</div>
                      </div>
                      <div className="text-center">
                        <div className="text-2xl font-bold text-gray-900">
                          {/* 오늘의 커밋 수 계산 */}
                          {userData.githubData?.contributions[new Date().toISOString().split('T')[0]] || 0}
                        </div>
                        <div className="text-xs text-gray-500">오늘</div>
                      </div>
                      <div className="text-center">
                        <div className="text-2xl font-bold text-gray-900">
                          {/* 현재 스트릭 계산 */}
                          {Object.entries(userData.githubData?.contributions || {})
                            .filter(([date]) => new Date(date) <= new Date())
                            .reverse()
                            .reduce((streak, [_, count]) => count > 0 ? streak + 1 : 0, 0)}
                        </div>
                        <div className="text-xs text-gray-500">연속</div>
                      </div>
                    </div>

                    {/* 미니 그래프 */}
                    <div className="h-20 overflow-hidden">
                      <ContributionMiniGraph contributions={userData.githubData?.contributions} />
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

// 간단한 미니 그래프 컴포넌트
const ContributionMiniGraph = ({ contributions }) => {
  if (!contributions) return null;

  // 최근 2주간의 데이터만 표시
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  const recentDates = Object.entries(contributions)
    .filter(([date]) => new Date(date) >= twoWeeksAgo)
    .sort((a, b) => new Date(a[0]) - new Date(b[0]));

  return (
    <div className="flex items-end justify-between h-full gap-1">
      {recentDates.map(([date, count]) => {
        const height = Math.min((count / 10) * 100, 100); // 최대 높이를 100%로 제한
        return (
          <div
            key={date}
            className="flex-1 bg-green-100 rounded-sm"
            style={{
              height: `${height}%`,
              backgroundColor: count === 0 ? '#ebedf0' :
                count <= 3 ? '#9be9a8' :
                count <= 6 ? '#40c463' :
                count <= 9 ? '#30a14e' : '#216e39'
            }}
            title={`${date}: ${count} contributions`}
          />
        );
      })}
    </div>
  );
};

export default DashboardPage;