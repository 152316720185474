import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GithubAuthProvider, 
  signInWithPopup, 
  signOut, 
  deleteUser,
  reauthenticateWithPopup 
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC6fZ3QqKlBRyItpXRALO7LwpTBdB5-5H4",
  authDomain: "sidehub-app.firebaseapp.com",
  projectId: "sidehub-app",
  storageBucket: "sidehub-app.firebasestorage.app",
  messagingSenderId: "193957901957",
  appId: "1:193957901957:web:b4ea03d5cfd49326615bec",
  measurementId: "G-EB4MZM81JS"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const signInWithGithub = async () => {
  const provider = new GithubAuthProvider();
  provider.addScope('read:user');     // 기본 프로필 정보만 요청

  try {
    const result = await signInWithPopup(auth, provider);
    const userData = {
      user: result.user,
      githubUsername: result.user.reloadUserInfo.screenName,
      shareGitHubActivity: false // 기본적으로 비공개
    };

    return { userData };
  } catch (error) {
    console.error('GitHub 로그인 에러:', error);
    throw error;
  }
};

// 사용자 재인증
const reauthenticateUser = async () => {
  const provider = new GithubAuthProvider();
  const currentUser = auth.currentUser;
  
  try {
    await reauthenticateWithPopup(currentUser, provider);
    return true;
  } catch (error) {
    console.error('Reauthentication failed:', error);
    throw error;
  }
};

// 사용자 계정 삭제
export const deleteUserAccount = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error('No authenticated user');
  
  try {
    // 재인증 먼저 수행
    await reauthenticateUser();
    // 재인증 성공 후 계정 삭제
    await deleteUser(currentUser);
    return true;
  } catch (error) {
    console.error('Error deleting user account:', error);
    throw error;
  }
};

export const signOutUser = () => signOut(auth);