import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';

const GITHUB_GRAPHQL_URL = 'https://api.github.com/graphql';
const GITHUB_TOKEN = process.env.REACT_APP_GITHUB_TOKEN;


// GraphQL query to fetch user's contributions
const GET_USER_CONTRIBUTIONS = `
  query($username: String!, $from: DateTime!, $to: DateTime!) {
    user(login: $username) {
      name
      contributionsCollection(from: $from, to: $to) {
        contributionCalendar {
          totalContributions
          weeks {
            contributionDays {
              contributionCount
              date
              weekday
              color
            }
          }
        }
      }
    }
  }
`;

export const fetchUserContributions = async (username) => {
  if (!username) {
    console.error('Username is required');
    return null;
  }

  // Token debugging
  console.log('Token available:', !!GITHUB_TOKEN);
  console.log('Token prefix:', GITHUB_TOKEN?.substring(0, 4));

  if (!GITHUB_TOKEN) {
    console.error('GitHub token is not configured');
    return null;
  }

  try {
    const to = new Date();
    const from = new Date();
    from.setFullYear(from.getFullYear() - 1);

    console.log('Making GraphQL request with token prefix:', GITHUB_TOKEN.substring(0, 4));

    const response = await fetch(GITHUB_GRAPHQL_URL, {
      method: 'POST',
      headers: {
        'Authorization': `bearer ${GITHUB_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: GET_USER_CONTRIBUTIONS,
        variables: {
          username,
          from: from.toISOString(),
          to: to.toISOString(),
        },
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('GitHub API Response:', {
        status: response.status,
        statusText: response.statusText,
        body: errorText
      });
      throw new Error(`GitHub API error: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    
    if (result.errors) {
      console.error('GitHub API Errors:', result.errors);
      throw new Error(result.errors[0].message);
    }

    const contributionCalendar = result.data?.user?.contributionsCollection?.contributionCalendar;
    
    if (!contributionCalendar) {
      console.error('No contribution data found');
      return null;
    }

    const contributions = {};
    let totalContributions = contributionCalendar.totalContributions;

    // Process the weeks data into a flat object
    contributionCalendar.weeks.forEach(week => {
      week.contributionDays.forEach(day => {
        contributions[day.date] = day.contributionCount;
      });
    });

    console.log('Fetched contributions:', {
      username,
      totalContributions,
      daysWithContributions: Object.keys(contributions).length
    });

    return {
      contributions,
      totalContributions,
      name: result.data.user.name
    };
  } catch (error) {
    console.error('Error fetching GitHub contributions:', error);
    return null;
  }
};

// Public한 사용자의 활동 데이터 조회
export const getPublicUserActivities = async () => {
  try {
    const usersRef = collection(db, 'users');
    // 단일 필드로 쿼리 수정
    const q = query(
      usersRef,
      where('shareGitHubActivity', '==', true)
    );
    
    const snapshot = await getDocs(q);
    // 클라이언트 측에서 githubUsername 필터링
    return snapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(user => user.githubUsername); // null이 아닌 경우만 필터링

  } catch (error) {
    console.error('Failed to fetch public user activities:', error);
    throw error;
  }
};