// 형용사와 명사 조합으로 랜덤 닉네임 생성
const adjectives = [
    '신나는', '즐거운', '열정적인', '꾸준한', '성실한', '창의적인', 
    '도전하는', '노력하는', '긍정적인', '성장하는', '열심히하는', '멋진'
  ];
  
  const nouns = [
    '개발자', '프로그래머', '엔지니어', '코더', '메이커', '창작자',
    '기획자', '디자이너', '조율사', '건축가', '탐험가', '연구원'
  ];
  
  export const generateRandomNickname = () => {
    const adj = adjectives[Math.floor(Math.random() * adjectives.length)];
    const noun = nouns[Math.floor(Math.random() * nouns.length)];
    return `${adj} ${noun}`;
  };