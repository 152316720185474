import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../services/firebase';
import { signOutUser } from '../services/firebase';
import { saveUser, getUser } from '../services/firestore';
import { generateRandomNickname } from '../utils/nicknameGenerator';
import { generateRandomAvatar } from '../utils/avatarGenerator';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      try {
        if (firebaseUser) {
          // 기존 사용자 정보 확인
          let userData = await getUser(firebaseUser.uid);
          
          // 새 사용자인 경우
          if (!userData) {
            const randomNickname = generateRandomNickname();
            // uid를 시드로 사용하여 일관된 아바타 생성
            const avatarUrl = generateRandomAvatar(firebaseUser.uid);
            
            const newUserData = {
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              displayName: firebaseUser.displayName,
              photoURL: avatarUrl, // GitHub 프로필 이미지 대신 랜덤 아바타 사용
              githubUsername: firebaseUser.reloadUserInfo.screenName,
              nickname: randomNickname,
              shareGitHubActivity: false,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            };

            await saveUser(newUserData);
            userData = newUserData;
          }
          
          setUser(userData);
        } else {
          setUser(null);
        }
      } catch (err) {
        console.error('Error in auth state change:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  // updateUser 함수 추가
  const updateUser = (updatedData) => {
    setUser(currentUser => ({
      ...currentUser,
      ...updatedData
    }));
  };

  const logout = async () => {
    try {
      await signOutUser();
      setUser(null);
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    error,
    logout,
    updateUser  // 추가된 함수
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};