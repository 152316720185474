import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useState } from 'react';

export default function Header() {
  const { user, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await logout();
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNavItemClass = (path) => {
    const baseClass = "px-3 py-2 rounded-md text-sm font-medium transition-colors";
    return location.pathname === path
      ? `${baseClass} bg-gray-100 text-gray-900`
      : `${baseClass} text-gray-600 hover:bg-gray-50 hover:text-gray-900`;
  };

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center gap-8">
            <Link to="/" className="text-xl font-bold">
              SideHub
            </Link>
            
            {user && (
              <nav className="hidden md:flex space-x-4">
                <Link 
                  to="/dashboard" 
                  className={getNavItemClass('/dashboard')}
                >
                  활동 현황
                </Link>
              </nav>
            )}
          </div>

          {user && (
            <div className="flex items-center gap-4">
              <Link 
                to="/profile"
                className="flex items-center gap-2 hover:bg-gray-50 p-2 rounded-md"
              >
                <img
                  src={user.photoURL}
                  alt={user.nickname || user.displayName}
                  className="w-8 h-8 rounded-full"
                />
                <span className="text-sm text-gray-700">
                  {user.nickname || user.displayName}
                </span>
              </Link>
              <button
                onClick={handleLogout}
                disabled={isLoading}
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                {isLoading ? '로그아웃 중...' : '로그아웃'}
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}