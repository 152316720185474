import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import ContributionGraph from './ContributionGraph';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/card';
import { fetchUserContributions } from '../../services/github';

const UserActivity = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contributions, setContributions] = useState(null);
  const [stats, setStats] = useState({
    total: 0,
    streak: 0,
    today: 0
  });

  useEffect(() => {
    loadUserActivity();
  }, [user?.githubUsername]);

  const loadUserActivity = async () => {
    if (!user?.githubUsername) {
      setLoading(false);
      return;
    }
  
    try {
      setLoading(true);
      setError(null);
      
      const data = await fetchUserContributions(user.githubUsername);
      
      if (!data) {
        setError('GitHub 활동 데이터를 가져올 수 없습니다.');
        return;
      }
      
      setContributions(data.contributions);
      
      // Calculate today's contributions
      const today = new Date().toISOString().split('T')[0];
      const todayCount = data.contributions[today] || 0;
  
      // Calculate current streak
      let currentStreak = 0;
      let date = new Date();
      
      while (true) {
        const dateStr = date.toISOString().split('T')[0];
        if (data.contributions[dateStr] > 0) {
          currentStreak++;
          date.setDate(date.getDate() - 1);
        } else {
          break;
        }
      }
  
      setStats({
        total: data.totalContributions,
        streak: currentStreak,
        today: todayCount
      });
  
    } catch (error) {
      console.error('Failed to load user activity:', error);
      setError('활동 데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!user?.githubUsername) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">
          GitHub 사용자명을 찾을 수 없습니다.
        </p>
      </div>
    );
  }

  const VisibilityBadge = () => (
    <div className={`
      flex items-center gap-2 px-3 py-1.5 rounded-full text-sm
      ${user.shareGitHubActivity 
        ? 'bg-green-50 text-green-700 border border-green-200' 
        : 'bg-amber-50 text-amber-700 border border-amber-200'}
    `}>
      <div className={`
        w-2 h-2 rounded-full animate-pulse
        ${user.shareGitHubActivity ? 'bg-green-500' : 'bg-amber-500'}
      `} />
      {user.shareGitHubActivity ? '공개' : '비공개'}
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Stats Cards with Visibility Badge */}
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-900">내 활동</h2>
        <VisibilityBadge />
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium text-gray-500">
              오늘의 커밋
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.today}</div>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium text-gray-500">
              현재 연속 스트릭
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.streak}일</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium text-gray-500">
              전체 커밋 수
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{stats.total}</div>
          </CardContent>
        </Card>
      </div>

      {/* Contribution Graph */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>활동 그래프</CardTitle>
          {!user.shareGitHubActivity && (
            <div className="text-sm text-amber-600 flex items-center gap-2">
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              다른 사용자에게는 비공개 상태입니다
            </div>
          )}
        </CardHeader>
        <CardContent>
          {error ? (
            <div className="text-center text-red-600 py-4">{error}</div>
          ) : (
            contributions && <ContributionGraph contributions={contributions} />
          )}
        </CardContent>
      </Card>

      {/* GitHub Profile Link */}
      <div className="flex justify-between items-center">
        <a
          href={`https://github.com/${user.githubUsername}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
        >
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
            <path d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385.6.105.825-.255.825-.57 0-.285-.015-1.23-.015-2.235-3.015.555-3.795-.735-4.035-1.41-.135-.345-.72-1.41-1.23-1.695-.42-.225-1.02-.78-.015-.795.945-.015 1.62.87 1.845 1.23 1.08 1.815 2.805 1.305 3.495.99.105-.78.42-1.305.765-1.605-2.67-.3-5.46-1.335-5.46-5.925 0-1.305.465-2.385 1.23-3.225-.12-.3-.54-1.53.12-3.18 0 0 1.005-.315 3.3 1.23.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23.66 1.65.24 2.88.12 3.18.765.84 1.23 1.905 1.23 3.225 0 4.605-2.805 5.625-5.475 5.925.435.375.81 1.095.81 2.22 0 1.605-.015 2.895-.015 3.3 0 .315.225.69.825.57A12.02 12.02 0 0024 12c0-6.63-5.37-12-12-12z"/>
          </svg>
          GitHub 프로필 보기
        </a>
        <a
          href="/profile"
          className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-1"
        >
          프로필 설정에서 공개 여부 변경
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default UserActivity;